<template>
  <div>
    <div class="mb-4">
      <div class="btn-back ml-5" @click="backToPreviousPage">
        <img src="@/assets/Icons/arrow-left-line.svg" alt="back" height="35" />
      </div>
    </div>

    <div class="">
      <div>
        <div class="wrappere">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12 mt-2">
            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-6 mb-5">
                <div class="card dp-card dp-card2">
                  <div
                    class="dashboard card-header d-flex"
                    style="justify-content: space-between"
                  >
                    <div class="w-50 text">Platform Wise Budget Split</div>
                    <div style="width: 30%">
                      <w-dropdown
                        :placeHolder="'Select'"
                        :options="platformWiseOptions"
                        @input="selectplatform"
                        :selectedOption="selectedPlatform"
                      ></w-dropdown>
                    </div>
                  </div>
                  <ColumnChart :chartData="platformWiseBudgetSplit" />
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mb-5">
                <div class="card dp-card dp-card2">
                  <h3 class="text">AdType Wise Budget Split</h3>
                  <PieChart
                    :series="adTypeWiseBudgetSplit"
                    :legendDetails="pieLegend"
                    :annotation="''"
                  />
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mb-5">
                <div class="card dp-card dp-card2">
                  <div
                    class="dashboard card-header d-flex"
                    style="justify-content: space-between"
                  >
                    <div class="w-50 text">
                      Channel Wise {{ selectedChannelSplit.text }} Split
                    </div>
                    <div style="width: 30%">
                      <w-dropdown
                        :placeHolder="'Select'"
                        :options="ChannelDropdownOption"
                        @input="selectChannelSplit"
                        :selectedOption="selectedChannelSplit"
                      ></w-dropdown>
                    </div>
                  </div>
                  <ColumnChart :chartData="channelWiseImpressionSplit" />
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mb-5">
                <div class="card dp-card dp-card2">
                  <h3 class="text">Channel Wise Reach Split</h3>
                  <PieChart
                    :series="reachWiseBudgetSplit"
                    :legendDetails="pieLegend"
                    :annotation="''"
                  />
                </div>
              </div>

              <div class="mb-5">
                <w-table
                  :tableProperties="tableDataValues"
                  :tableLoading="isTableLoding"
                  class="platform"
                ></w-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "@/widgets/DataTable.vue";
import DropDown from "@/widgets/Dropdown.vue";
import ColumnChart from "@/components/Chart/ColumnChart.vue";
import PieChart from "@/components/Chart/PieChart.vue";
export default {
  components: {
    "w-table": DataTable,
    ColumnChart,
    PieChart,
    "w-dropdown": DropDown,
  },
  props: [
    "isTableLoding",
    "tableDataValues",
    "reachWiseBudgetSplit",
    "adTypeWiseBudgetSplit",
    "channelWiseImpressionSplit",
    "platformWiseBudgetSplit",
    "platformWiseOptions",
    "category",
    // "pieLegend"
  ],
  data() {
    return {
      pieLegend: {
        // layout: "horizontal",
        // align: "right",
        // verticalAlign: "middle",
        itemMarginTop: 20,
        // itemMarginRight:15,
        itemMarginBottom: -20,
      },
      overallPieChartOptions: {
        // width:500,
        sliceVisibilityThreshold: 0,
        height: 300,
        pieHole: 0.5,
        chartArea: { left: 20, top: 50, width: "100%", height: "75%" },
        colors: [
          "#d92e72",
          "#05d0ef",
          "#fcba03",
          "ADFF2F",
          "#DA70D6",
          "BC8F8F",
          "#d92e72",
          "#ff9d4a",
          "#33ffcc",
          "#a62dc6",
        ],
        backgroundColor: "#3c4c66",
        pieSliceBorderColor: "none",
        pieSliceTextStyle: { color: "#fff", fontSize: 14 },
        pieSliceText: "value",
        legend: {
          position: "right",
          textStyle: { color: "#e5e5e5", fontSize: 14 },
          alignment: "center",
          labeledValueText: "value",
        },
        tooltip: {
          trigger: "true",
          showColorCode: true,
          text: "both",
        },
      },
      // sponsorshipChartData: [
      //   ["Name", "Value", { role: "annotation" }],
      //   ["A", 8, "1"],
      //   ["B", 44, "44"],
      //   ["C", 24, "24"],
      //   ["D", 12, "12"],
      // ],
      selectedChannelSplit: { id: "Impressions", text: "Impressions" },
      selectedPlatform: { id: "All", text: "all" },

      ChannelDropdownOption: [
        {
          id: "Impressions",
          text: "Impressions",
        },
        {
          id: "Clicks",
          text: "Clicks",
        },
        {
          id: "Video Views",
          text: "VideoViews",
        },
        {
          id: "Engagements",
          text: "Engagements",
        },
      ],
      tableLoding: this.isTableLoding,
      legendPositionNone: "none",
      totalRows: this.totalRowsDetail,
      externalColumnChartOptions: {
        // width: 300,
        bar: { groupWidth: 60 },
        height: 300,
        chartArea: {
          bottom: 40,
          top: 50,
        },
        annotations: {
          textStyle: {
            color: "#e5e5e5",
            fontSize: 13,
          },
        },
        vAxis: {
          format: "#'%'",
          //ticks: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],

          textStyle: { color: "#e5e5e5" },
          baselineColor: "#e5e5e5",
        },
        hAxis: {
          textStyle: { color: "#e5e5e5" },
          baselineColor: "#e5e5e5",
        },
        colors: ["#d92e72"],
        backgroundColor: "#3c4c66",
        legend: "none",
      },
    };
  },
  methods: {
    backToPreviousPage() {
      this.$emit("backToPreviousPage", true);
    },
    selectChannelSplit(event) {
      this.selectedChannelSplit = event;
      this.$emit("selectedChannel", this.selectedChannelSplit.text);
    },
    selectplatform(event) {
      this.selectedPlatform = event;
      this.$emit("selecctedPlatform", this.selectedPlatform.text);
    },
  },
};
</script>

<style scoped>
.wrapper {
  border-radius: 10px;
  background-color: #fff;
}
</style>
<style>
.dashboard.card-header {
  background-color: white;
  font-size: 20px;
  border-bottom-width: 0px;
  font-family: "ProximaNovaRegular";
}
.dp-card2 {
  padding: 2rem;
  height: 520px;
}

.card {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
}

.text {
  font-weight: bold;
}
.platform table thead tr th {
  background-color: #fff;
}
.platform table thead tr {
  background-color: #fff;
}
.platform table {
  border: 1px solid;
}
.platform tbody tr:last-child {
  background: #fff;
}
.b-table-details tbody tr:nth-of-type(odd) {
  background: #fff;
}
.b-table-details tbody tr:nth-of-type(2n) {
  background: #fff;
}
</style>
